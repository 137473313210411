import { GeneratedEmailModuleContentDataType } from '../types/Content';

/**
 * Sorts an array of `GeneratedEmailModuleContentDataType` objects by their version sequence.
 *
 * This function takes an array of `GeneratedEmailModuleContentDataType` objects and sorts them
 * in ascending order based on the `versionSequence` property. It is useful for
 * organizing content modules in the order they were created or updated.
 *
 * @param {GeneratedEmailModuleContentDataType[]} contentsArray - An array of `GeneratedEmailModuleContentDataType` objects to be sorted.
 * @returns {GeneratedEmailModuleContentDataType[]} The sorted array of `GeneratedEmailModuleContentDataType` objects.
 *
 * @example
 * const contents = [
 *   { versionSequence: 3, name: 'Module C' },
 *   { versionSequence: 1, name: 'Module A' },
 *   { versionSequence: 2, name: 'Module B' }
 * ];
 *
 * const sortedContents = sortContentsArrayForExport(contents);
 * console.log(sortedContents);
 * // Output: [
 * //   { versionSequence: 1, name: 'Module A' },
 * //   { versionSequence: 2, name: 'Module B' },
 * //   { versionSequence: 3, name: 'Module C' }
 * // ]
 */
const sortContentsArrayForExport = (
  contentsArray: GeneratedEmailModuleContentDataType[]
) => contentsArray?.sort((a, b) => a.versionSequence - b.versionSequence);

export default sortContentsArrayForExport;
