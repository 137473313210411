import { CLAIMS_REFERENCES } from '../constants/apiRoutes';
import {
  ReferencesResponse,
  ReferencesResult,
  ApiError,
} from '../types/Content';
import { httpClient } from '../utils/httpClient';
import Logger from '../utils/logger';

export const fetchReferences = async (
  contentId: string,
  sessionId: string,
  action: string
): Promise<ReferencesResponse> => {
  Logger.info(contentId);
  try {
    const response = await httpClient.get<ReferencesResult>(
      CLAIMS_REFERENCES(contentId, sessionId, action)
    );

    const { data, success } = response as ReferencesResult;
    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const fetchError = error as ApiError;
    Logger.error(new Error(fetchError.message));
    return {
      success: false,
    };
  }
};
