import { get } from 'lodash';
import store from '../../index';
import {
  MarketingFormFieldRequest,
  SecondaryFormApiField,
  VersionPayload,
} from '../../../../types/MarketingBox';
import { ParamsUsed } from '../../../../types/store/markettingContentGeneration/majorProcessActions';
import { getContentGroupId, getSessionId } from '../../../../utils/commonUtils';
import {
  getCurrentSelectedLanguage,
  getEncodedUserNtid,
  getGenerateContentPayload,
  getParamsUsed,
  getParentId,
  getRefinementAdditionalParams,
  getVersionIds,
  resetTranslationState,
} from '../../helpers/common';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { fetchRegeneratedContent } from '../../../../services/Content';
import { DSPGeneratedContent } from '../../../../components/molecules/DSPRenderer/DSPRenderer.types';
import {
  ANALYTICS,
  ContentType,
  operationType,
  REGENERATION_LIMIT,
  summarizationStatuses,
} from '../../../../constants/common';
import {
  trackConfidenceScore,
  trackConfidenceScoreForPrimaryMessage,
  trackContentConfidenceScore,
  trackGenerateContentComplete,
  trackInputValues,
  trackSecondaryClaims,
  trackSystemErrors,
} from '../../../../services/Analytics';
import { preGenerateCliamsStateSegment } from '../../stateSegments/preGenerateClaimsStateSegment';
import { moduleContentsStateSegment } from '../../stateSegments/emailModulesStateSegment';
import { generateStateSegment } from '../../stateSegments/generateStateSegment';
import { getGeneratedContentValidations } from '../microProcessActions/getGeneratedContentValidation';

export const regenerateContent = async (paramsUsed: ParamsUsed) => {
  resetTranslationState();

  const currentSelectedLanguage = getCurrentSelectedLanguage();
  const generatedContentPayload = getGenerateContentPayload(paramsUsed);
  const refinePayload = getRefinementAdditionalParams(paramsUsed);

  const parentId = getParentId();
  const versionIds = getVersionIds();

  type RegenerateContentPayload = MarketingFormFieldRequest &
    SecondaryFormApiField &
    VersionPayload & { sessionId: string };
  const requestPayload: RegenerateContentPayload = {
    ...generatedContentPayload,
    ...refinePayload,
    sessionId: getSessionId(),
    contentGroupId: getContentGroupId(),
    parentId,
    versionIds,
    operationType: operationType.REGENERATE,
  };

  store.set(contentListStateSegment, (prev) => {
    return {
      ...prev,
      isReGeneratingContent: true,
      retryPromptParams: paramsUsed,
    };
  });

  const { success, data } = await fetchRegeneratedContent(requestPayload);

  const contentId = get(data, 'id', '');
  const summarizationStatus = (data?.content as DSPGeneratedContent)
    ?.contentSummary?.summarizationStatus;

  const isDspSummarizationInitiated =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_INITIATED;

  const isDspError =
    requestPayload.contentType === ContentType.DSP &&
    summarizationStatus === summarizationStatuses.SUMMARIZATION_ERROR;

  if (isDspError) {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });
    trackSystemErrors(ANALYTICS.REGENERATE_FORM_NAME);
    return;
  }

  function trackAnalytics() {
    const { brand, contentType, topics, targetAudience, destinationFormat } =
      requestPayload;

    const { preFetchedclaimsList } = store.get(preGenerateCliamsStateSegment);

    const isSubstituteClaims = preFetchedclaimsList?.some(
      ({ isSubstitute }) => isSubstitute
    );

    const { emailModules, ...restPayload } = requestPayload;

    const shouldTrackEmailModules =
      contentType.toLowerCase() === ContentType.EMAIL.toLowerCase() ||
      (contentType.toLowerCase() === ContentType.DSP.toLowerCase() &&
        destinationFormat?.toLowerCase() === ContentType.EMAIL.toLowerCase());

    const regenerateInputValues = {
      ...restPayload,
      ...(isSubstituteClaims
        ? { 'secondary-claims': ANALYTICS.YES }
        : { 'secondary-claims': ANALYTICS.NO }),
      ...(shouldTrackEmailModules && {
        emailModules,
      }),
      language: currentSelectedLanguage,
      [ANALYTICS.USER_ENCODED_NTID_QUESTION_NAME]: getEncodedUserNtid(),
    };

    const channel =
      (contentType === ContentType.DSP && destinationFormat) || contentType;

    trackInputValues(
      ANALYTICS.REGENERATE_FORM_NAME,
      ANALYTICS.STEP,
      regenerateInputValues
    );

    if (isSubstituteClaims) {
      trackSecondaryClaims(
        ANALYTICS.SECONDARY_CLAIMS_FORM_NAME,
        brand,
        contentType,
        targetAudience,
        getEncodedUserNtid()
      );
    }

    // track confidence score for regenerate content / brand and primary message
    if (data?.score?.value) {
      trackContentConfidenceScore(
        ANALYTICS.REGENERATE_FORM_NAME,
        data?.score?.value,
        Object.keys(regenerateInputValues).length + 1 // index for the confidence score question
      );

      trackConfidenceScore(
        ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
        brand,
        getEncodedUserNtid(),
        data?.score?.value
      );

      if (topics) {
        trackConfidenceScoreForPrimaryMessage(
          ANALYTICS.CONFIDENCE_SCORE_FORM_NAME,
          topics,
          data?.score?.value
        );
      }
    }

    if (channel.toLowerCase() !== ContentType.DSP.toLowerCase()) {
      trackGenerateContentComplete(
        ANALYTICS.REGENERATE_FORM_NAME,
        brand,
        contentType,
        getEncodedUserNtid()
      );
    }
  }

  if (success && !isDspSummarizationInitiated) {
    const { contentType, destinationFormat, parentId: id } = requestPayload;

    if (
      contentType === 'email' ||
      destinationFormat?.toLowerCase() === 'email' ||
      destinationFormat?.toLowerCase() === 'rte'
    ) {
      const moduleContents = new Map(
        Array.from(store.get(moduleContentsStateSegment))?.values()
      );

      const moduleContent = Array.from(moduleContents.values())?.find(
        (module) => module?.parentContentId === id
      );

      if (moduleContent) {
        const { mapKey, refineConfig, translationConfig } = moduleContent || {};

        const wasLastContentRefined =
          refineConfig?.[Object.keys(refineConfig).length - 1]?.isRefineApplied;

        const refineConfigOption = {
          isRefineApplied: wasLastContentRefined,
          operationType: requestPayload?.operationType,
          inclusions: requestPayload?.inclusions,
          exclusions: requestPayload?.exclusions,
          toneStyle: requestPayload?.toneStyle,
        };

        moduleContents.set(mapKey, {
          ...moduleContent,
          translationConfig: {
            ...translationConfig,
            [Object.keys(translationConfig)?.length]: currentSelectedLanguage,
          },
          refineConfig: {
            ...refineConfig,
            [Object.keys(refineConfig)?.length]: refineConfigOption,
          },
        });

        store.set(moduleContentsStateSegment, moduleContents);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store.set(contentListStateSegment, (prev: any) => {
      const wasLastContentRefined =
        prev?.contentList?.[prev.contentList.length - 1]?.isRefineApplied;

      const content = {
        [currentSelectedLanguage]: data,
        paramsUsed: getParamsUsed(paramsUsed),
        regenerationLimit: REGENERATION_LIMIT - 1,
        apiPayloadUsed: requestPayload,
        isRefineApplied: wasLastContentRefined,
      };

      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: false,
        errorMessage: '',
        isGeneratingContent: false,
        contentList: [...prev.contentList, content] as never,
      };
    });

    trackAnalytics();

    // fetch generated content validation
    getGeneratedContentValidations({
      contentId,
      action: get(generatedContentPayload, 'action', ''),
      sessionId: getSessionId(),
      contentGroupId: getContentGroupId(),
    });
  } else if (success && isDspSummarizationInitiated) {
    const content = data?.content as DSPGeneratedContent;

    store.set(generateStateSegment, {
      contentId: content?.id,
      generateContentInitiated: true,
      paramsUsed,
      primaryLanguage: currentSelectedLanguage,
      apiPayloadUsed: requestPayload,
      isReGeneratingContent: true,
    });

    trackAnalytics();
  } else {
    store.set(contentListStateSegment, (prev) => {
      return {
        ...prev,
        isReGeneratingContent: false,
        errorReGeneratingContent: true,
        errorMessageGeneratingContent:
          'There has been an error in generating content',
      };
    });

    trackSystemErrors(ANALYTICS.REGENERATE_FORM_NAME);
  }
};
