import { FC } from 'react';
import styles from './Tag.module.css';

type TagProps = {
  text: string;
  variant: TagVariant;
  icon: JSX.Element | null;
  isDisabled?: boolean;
  classes?: string;
};

export enum TagVariant {
  DEFAULT = 'DEFAULT',
  NEUTRAL = 'NEUTRAL',
  INFO = 'INFO',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INPROGRESS = 'INPROGRESS',
}

const classByVariant = {
  [TagVariant.DEFAULT]: styles['tag--default'],
  [TagVariant.NEUTRAL]: styles['tag--neutral'],
  [TagVariant.INFO]: styles['tag--info'],
  [TagVariant.WARNING]: styles['tag--warning'],
  [TagVariant.SUCCESS]: styles['tag--success'],
  [TagVariant.ERROR]: styles['tag--error'],
  [TagVariant.INPROGRESS]: styles['tag--inprogress'],
};

const Tag: FC<TagProps> = ({
  text,
  variant,
  icon = null,
  classes = '',
  isDisabled = false,
}) => {
  return (
    <div
      className={`${styles.tag__wrapper} ${classByVariant[variant]} ${
        isDisabled ? styles['tag--disabled'] : ''
      } ${classes}`}
    >
      <p className={styles.tag__text}>{text}</p>
      {icon}
    </div>
  );
};

export default Tag;
