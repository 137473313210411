import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { useAtomValue as useStateSegmentValue } from 'jotai';
import { emitCustomEvent } from 'react-custom-events';
import {
  RiAddFill,
  RiAiGenerate,
  RiArrowLeftDoubleFill,
  RiArrowRightDoubleFill,
  // RiHistoryLine,
  // RiHomeLine,
  RiUpload2Line,
  RiSettings3Line,
  RiHomeLine,
} from '@remixicon/react';
import { RESET } from 'jotai/utils';
import styles from './NavigationPanel.module.css';
import {
  ACTIVE_MENU_BY_ROUTE,
  MENU_NAMES,
  ROLE,
  ANALYTICS,
  PAGE_ROUTES,
} from '../../../constants/common';
import { trackAdminPageVisit } from '../../../services/Analytics';
import NavSection from '../../molecules/NavSection';
import PfizerLogo from '../../atoms/PfizerLogo';
import Button from '../../atoms/Button';
import { translate } from '../../../localization';
import { startNewStateSegment } from '../../../store/markettingContentGeneration/stateSegments/startNewStateSegment';
import { clearAllValues } from '../../../store/markettingContentGeneration/actions/majorProcessActions';
import { filtersStateSegment } from '../../../store/globalStore/stateSegments/filtersStateSegment';
import store from '../../../store/markettingContentGeneration';
import QualityCheck from '../../atoms/QualityIcon';
import GreenCircleIcon from '../../atoms/GreenCircleIcon';
import { GenerateContentValidationStateSegment } from '../../../store/markettingContentGeneration/stateSegments/GenerateContentValidationStateSegment';
import { contentPaginationStateSegment } from '../../../store/markettingContentGeneration/stateSegments/contentPaginationStateSegment';
import { contentParamsSegment } from '../../../store/markettingContentGeneration/stateSegments/paramsSegment';
import { contentListStateSegment } from '../../../store/markettingContentGeneration/stateSegments/contentListSegment';
import Tooltip, { ToolTipSize, TooltipVariant } from '../../atoms/Tooltip';

interface NavigationPanelProps {
  isExpanded: boolean;
  onNavToggle: (value: boolean) => void;
  activeMenu: string;
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
}

const NavigationPanel: FC<NavigationPanelProps> = ({
  isExpanded,
  onNavToggle,
  activeMenu,
  setActiveMenu,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdminUser =
    sessionStorage.getItem('pfz-cggenai-user-role') === ROLE.ADMIN;

  const { allowStartNew } = useStateSegmentValue(startNewStateSegment);
  const pathname = get(location, 'pathname', '');
  const fileUpload = get(location, 'state.fileUpload', '');
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const handleNavMenuClicked = (name: string) => {
    if (name === 'access' && isAdminUser) {
      navigate('/user-management', {
        state: {
          isNavPanelOpen: isExpanded,
          selectedMenu: name,
        },
      });
      // track analytics for  admin page visit
      trackAdminPageVisit(ANALYTICS.ADMIN_PAGE_VISIT_NAME);
    } else {
      switch (name) {
        case 'pfizer-logo':
        case MENU_NAMES.CREATE: {
          navigate(PAGE_ROUTES.GENERATE_CONTENT, {
            state: {
              isNavPanelOpen: isExpanded,
            },
          });
          if (fileUpload || !pathname.includes('/generate-content')) {
            clearAllValues();
            store.set(filtersStateSegment, RESET);
          }
          break;
        }
        case 'create-new': {
          emitCustomEvent('start-new');
          break;
        }
        case MENU_NAMES.UPLOAD: {
          navigate(PAGE_ROUTES.UPLOAD_GENERATE, {
            state: {
              isNavPanelOpen: isExpanded,
            },
          });

          break;
        }
        case MENU_NAMES.CLOSE_SIDE_BAR: {
          onNavToggle(!isExpanded);

          break;
        }
        case MENU_NAMES.HISTORY: {
          break;
        }
        case MENU_NAMES.HOME: {
          navigate(PAGE_ROUTES.HOME, {
            state: {
              isNavPanelOpen: isExpanded,
            },
          });

          break;
        }
        case MENU_NAMES.QUALITY_VALIDATION: {
          navigate(PAGE_ROUTES.QUALITY_VALIDATION, {
            state: {
              isNavPanelOpen: isExpanded,
            },
          });

          break;
        }

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (pathname) setActiveMenu(ACTIVE_MENU_BY_ROUTE[pathname]);
  }, [pathname]);

  const userMenuItems = [
    {
      icon: <RiHomeLine size={24} />,
      text: translate('home'),
      name: MENU_NAMES.HOME,
      tooltip: translate('home'),
    },
    {
      icon: <RiAiGenerate size={24} />,
      text: translate('create'),
      name: MENU_NAMES.CREATE,
      tooltip: translate('create'),
    },
    {
      icon: <RiUpload2Line size={24} />,
      text: translate('upload'),
      name: MENU_NAMES.UPLOAD,
      tooltip: translate('upload_beta'),
    },
    // {
    //   icon: <RiHistoryLine size={24} />,
    //   text: translate('history'),
    //   name: MENU_NAMES.HISTORY,
    // },
  ];

  const adminMenuItems = [
    {
      icon: <RiSettings3Line size={24} />,
      text: translate('access'),
      name: MENU_NAMES.ACCESS,
      tooltip: translate('access'),
    },
  ];

  const shouldShowQualityCheck = true;

  const { currentSelectedLanguage } =
    useStateSegmentValue(contentParamsSegment);

  const { contentList: contentItems } = useStateSegmentValue(
    contentListStateSegment
  );

  const currentViewVersion = useStateSegmentValue(
    contentPaginationStateSegment
  );

  const contentId = get(
    contentItems[currentViewVersion],
    `${currentSelectedLanguage}.id`,
    ''
  );

  const generateContentValidation = useStateSegmentValue(
    GenerateContentValidationStateSegment
  );

  const currentContentValidation = generateContentValidation[contentId];
  const hasValidationsComplete =
    currentContentValidation &&
    currentContentValidation.validations &&
    Object.keys(currentContentValidation.validations).length &&
    !currentContentValidation.error;

  const otherMenuItems = shouldShowQualityCheck
    ? [
        {
          icon: (
            <>
              <QualityCheck />
              {hasValidationsComplete && (
                <div
                  className={`${
                    isExpanded
                      ? styles['expanded-green-circle-icon']
                      : styles['green-circle-icon']
                  }`}
                >
                  <GreenCircleIcon />
                </div>
              )}
            </>
          ),
          text: translate('quality_validation'),
          name: MENU_NAMES.QUALITY_VALIDATION,
          tooltip: translate('quality_validation'),
        },
      ]
    : [];

  const footerMenuItems = [
    {
      icon: isExpanded ? (
        <RiArrowLeftDoubleFill size={24} />
      ) : (
        <RiArrowRightDoubleFill size={24} />
      ),
      text: translate('close_sidebar'),
      name: MENU_NAMES.CLOSE_SIDE_BAR,
      tooltip: translate('expand_sidebar'),
    },
  ];

  return (
    <nav
      className={`${styles.navbar} ${isExpanded ? '' : styles.collapsed}`}
      aria-label="Main Navigation"
      key="pf-nav-menu"
    >
      <div
        className={`${styles['brand-nav-section']} ${
          isExpanded ? '' : styles.collapsed
        }`}
      >
        <PfizerLogo
          isExpanded={isExpanded}
          onClick={handleNavMenuClicked}
          className={styles['brand-logo']}
        />
        <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          <Tooltip
            text={translate('create_new_tooltip')}
            showTooltip={allowStartNew && isTooltipVisible}
            onClickedOutside={() => setTooltipVisible(false)}
            variant={TooltipVariant.TOP_RIGHT}
            size={ToolTipSize.REGULAR}
            theme="default"
            id="create-new-tooltip"
            classes={styles.createNewBtnTooltip}
          >
            <Button
              id="create-new"
              className={`${styles['create-new']} ${
                isExpanded ? styles.expanded : styles.collapsed
              }`}
              aria-label="Create New"
              onClick={() => handleNavMenuClicked('create-new')}
              type="button"
              colorScheme="primary"
              variant="outline"
              size="lg"
              isDisabled={!allowStartNew}
            >
              <RiAddFill size={20} />
              {isExpanded && (
                <span className={styles.text}>{translate('create_new')}</span>
              )}
            </Button>
          </Tooltip>
        </div>
      </div>
      <NavSection
        items={userMenuItems}
        isExpanded={isExpanded}
        onNavMenuClicked={handleNavMenuClicked}
        className={styles['users-menu']}
        activeMenu={activeMenu}
      />
      {isAdminUser && (
        <NavSection
          items={adminMenuItems}
          isExpanded={isExpanded}
          onNavMenuClicked={handleNavMenuClicked}
          className={styles['admin-menu']}
          activeMenu={activeMenu}
        />
      )}
      <NavSection
        items={otherMenuItems}
        isExpanded={isExpanded}
        onNavMenuClicked={handleNavMenuClicked}
        className={styles['other-menu']}
        activeMenu={activeMenu}
      />
      <NavSection
        items={footerMenuItems}
        isExpanded={isExpanded}
        onNavMenuClicked={handleNavMenuClicked}
        className={styles['footer-menu']}
      />
    </nav>
  );
};

NavigationPanel.displayName = 'NavigationPanel';

export default React.memo(NavigationPanel);
