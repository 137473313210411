import get from 'lodash/get';
import { fetchReferences } from '../../../../services/References';
import {
  ClaimReferences,
  ContentGeneratedResult,
  ContentTranslatedResult,
  ReferencesResponseData,
} from '../../../../types/Content';
import store from '../../index';
import { contentClaimsStateSegment } from '../../stateSegments/contentClaimsStateSegment';
import {
  claimsSequenceStateSegment,
  ClaimsSequenceType,
} from '../../stateSegments/claimsSequenceStateSegment';
import { ContentType } from '../../../../constants/common';
import { emailContentParentId } from '../../stateSegments/emailModulesStateSegment';

export const getClaimsReferences = async (
  contentId: string,
  noOfRetries: number,
  sessionId: string,
  action: string
) => {
  store.set(contentClaimsStateSegment, (prev) => {
    return {
      ...prev,
      isLoading: true,
      map: {
        ...prev.map,
        [contentId]: {
          claims: [],
          retries: noOfRetries,
          errorLoadingClaims: false,
          errorMessage: '',
        },
      },
    };
  });

  const { data, success } = await fetchReferences(contentId, sessionId, action);
  if (success && data) {
    setTimeout(() => {
      store.set(contentClaimsStateSegment, (prev) => {
        return {
          ...prev,
          isLoading: false,
          map: {
            ...prev.map,
            [contentId]: {
              claims: (data as ReferencesResponseData).contentReferences, // updated key from claimReferences as per swagger
              retries: noOfRetries,
              errorLoadingClaims: false,
              errorMessage: '',
            },
          },
        };
      });
    }, 3000);
  } else {
    store.set(contentClaimsStateSegment, (prev) => {
      return {
        ...prev,
        isLoading: false,
        map: {
          ...prev.map,
          [contentId]: {
            errorLoadingClaims: true,
            errorMessage: 'There was an error loading claims.',
            claims: [],
            retries: noOfRetries,
          },
        },
      };
    });
  }
};

export const sequenceAndStoreClaims = (
  data: ContentGeneratedResult['data'] | ContentTranslatedResult['data'],
  isTranslatedContent: boolean = false
): ClaimsSequenceType => {
  const channelName = get(data, 'channel', '');
  const isEmailChannel =
    channelName.toLowerCase() === ContentType.EMAIL.toLowerCase() ||
    channelName.toLowerCase() === ContentType.RTE.toLowerCase();
  const emailParentId =
    store.get(emailContentParentId) ||
    get(
      data,
      isTranslatedContent
        ? 'translatedContent[0].id'
        : 'generatedContent[0].id',
      ''
    );
  const claimsSequences = store.get(claimsSequenceStateSegment);
  const claimReferences = store.get(contentClaimsStateSegment);
  const contentId = isEmailChannel
    ? emailParentId
    : get(
        data,
        isTranslatedContent
          ? 'translatedContent[0].id'
          : 'generatedContent[0].id',
        ''
      );
  const contentClaims = get(claimReferences, `map[${contentId}].claims`, []);
  if (Object.keys(claimsSequences).length || contentClaims.length) {
    return claimsSequences;
  }

  const contentReferences: ClaimReferences[] = get(
    data,
    isTranslatedContent
      ? 'translatedContent[0].contentReferences'
      : 'generatedContent[0].contentReferences',
    []
  );
  const seqObj: ClaimsSequenceType = {};
  const references = contentReferences.map(
    (ref: ClaimReferences, index: number) => {
      seqObj[ref.id] = `${index + 1}`;
      return {
        ...ref,
        sequence: `${index + 1}`,
      };
    }
  );
  store.set(contentClaimsStateSegment, {
    isLoading: false,
    map: {
      [contentId]: {
        claims: references as unknown as ClaimReferences[], // updated key from claimReferences as per swagger
        retries: 3,
        errorLoadingClaims: false,
        errorMessage: '',
      },
    },
  });
  store.set(claimsSequenceStateSegment, seqObj);
  return seqObj;
};
