import { FC, Suspense, lazy, useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { ROLE } from '../../../constants/common';
import AccessForbidden from '../AccessForbidden';
import PageLoader from '../../molecules/PageLoader';
import { clearAllValues } from '../../../store/markettingContentGeneration/actions/majorProcessActions';

const HomeScreen = lazy(() => import('../HomeScreen'));
const MarketingDashboard = lazy(() => import('../MarketingDashboard'));
const UserManagement = lazy(() => import('../UserManagement'));
const FileUploadDashboard = lazy(() => import('../FileUploadDashboard'));
const BrandsInformation = lazy(() => import('../BrandsInformation'));
// const ContentQualityCheck = lazy(() => import('../ContentQualityCheck'));

interface MarketingApplicationProps {
  isLoadMarketingWidget?: boolean;
  isLoadAccessWidget?: boolean;
  isLoadUploadView?: boolean;
  isLoadHomeWidget?: boolean;
  isLoadBrandsInformation?: boolean;
  // isLoadQualiltyCheck?: boolean;
}

const MarketingApplication: FC<MarketingApplicationProps> = ({
  isLoadAccessWidget = false,
  isLoadMarketingWidget = false,
  isLoadUploadView = false,
  isLoadHomeWidget = false,
  isLoadBrandsInformation = false,
  // isLoadQualiltyCheck = false,
}) => {
  const isAdminUser =
    sessionStorage.getItem('pfz-cggenai-user-role') === ROLE.ADMIN;
  useEffect(() => {
    /* we need to clear app data explicitly as jotai doesnot clears state on route change */
    return () => {
      clearAllValues();
    };
  }, []);
  return (
    <MainLayout>
      <Suspense fallback={<PageLoader />}>
        {isLoadMarketingWidget && <MarketingDashboard />}
        {isLoadAccessWidget &&
          (isAdminUser ? <UserManagement /> : <AccessForbidden />)}
        {isLoadUploadView && <FileUploadDashboard />}
        {isLoadHomeWidget && <HomeScreen />}
        {isLoadBrandsInformation && <BrandsInformation />}
        {/* {isLoadQualiltyCheck && <ContentQualityCheck />} */}
      </Suspense>
    </MainLayout>
  );
};

MarketingApplication.displayName = 'MarketingApplication';

export default MarketingApplication;
