import store from '../../index';
import { contentImagesStateSegment } from '../../stateSegments/contentImagesStateSegment';
import { GeneratedContentDataType } from '../../../../types/transformedData/MarkettingContentGeneration';
import { GeneratedEmailModuleContentDataType } from '../../../../types/Content';

/**
 * Updates the translated caption on the image segment based on the provided data.
 * @param contentId - The ID of the content to update.
 * @param data - The generated content data containing image recommendations.
 */
export const updateTranslatedCaptionOnImageSegment = async (
  contentId: string,
  data: GeneratedContentDataType
) => {
  const contentImagesState = store.get(contentImagesStateSegment);

  // Check if the contentId exists in the contentImagesState
  if (Object.prototype.hasOwnProperty.call(contentImagesState, contentId)) {
    const optionIdsHashMap = contentImagesState[contentId];
    const moduleContent = data.content as GeneratedEmailModuleContentDataType;

    // Check if imagesRecommend exists in the moduleContent
    if (moduleContent.imagesRecommend) {
      // Iterate over each image recommendation
      moduleContent.imagesRecommend.forEach((recommendation) => {
        const { optionId } = recommendation;
        const imageSegmentData = optionIdsHashMap[optionId];

        if (imageSegmentData && recommendation.recommendedImages) {
          recommendation.recommendedImages.forEach((recommendedImage) => {
            // Find the matching image in the contentImagesState
            const imageToUpdate = imageSegmentData.images.find(
              (image) => image.id === recommendedImage.imageId
            );

            // Update the translatedCaption if a match is found
            if (imageToUpdate) {
              imageToUpdate.translatedCaption = recommendedImage.caption;
            }
          });
        }
      });

      // Update the contentImagesStateSegment with the modified data
      store.set(contentImagesStateSegment, (prev) => {
        return {
          ...prev,
          [contentId]: optionIdsHashMap,
        };
      });
    }
  }
};
