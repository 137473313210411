import store from '../..';
import { GET_CLAIM_DETAILS } from '../../../../constants/apiRoutes';
import { ApiError } from '../../../../types/Content';
import { httpClient } from '../../../../utils/httpClient';
import Logger from '../../../../utils/logger';
import {
  ApiPayloadUsed,
  brandInformationStateSegment,
  Claim,
  Pagination,
} from '../../stateSegments/brandsInformationStateSegment';

export interface ClaimResponse {
  success: boolean;
  data?: {
    claimDetails: Claim[] | [];
    pagination: Pagination;
  };
}

export const getClaimDetails = async (
  apiPayload: ApiPayloadUsed
): Promise<ClaimResponse> => {
  const {
    brand: brandName,
    country: countryName,
    indication: indicationName,
    targetAudience: audiences,
    language,
    topics: primaryMessages,
    claimsPage,
    claimsPageSize,
  } = apiPayload;

  try {
    const response = await httpClient.get<ClaimResponse>(
      GET_CLAIM_DETAILS({
        countryName,
        brandName,
        indicationName,
        audiences,
        primaryMessages,
        language,
        page: claimsPage,
        pageSize: claimsPageSize,
      })
    );
    const { data, success } = response as ClaimResponse;

    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fetchClaimDetails = async (payload: ApiPayloadUsed) => {
  store.set(brandInformationStateSegment, (prev) => {
    return {
      ...prev,
      claimDetails: {
        ...prev.claimDetails,
        isLoading: true,
        errorMessage: '',
      },
    };
  });
  const { success, data } = await getClaimDetails({
    ...payload,
  });

  if (success && data) {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        claimDetails: {
          ...prev.claimDetails,
          data: {
            ...prev.claimDetails.data,
            claims: data.claimDetails ?? [],
            claimsPagination: data.pagination ?? {},
          },
          isLoading: false,
        },
      };
    });
  } else {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        claimDetails: {
          ...prev.claimDetails,
          isLoading: false,
          errorMessage: 'There has been an error in fetching segment details',
        },
      };
    });
  }

  return success;
};
