// API routes of the app

export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? ''
    : 'https://ar2uv78oq3.execute-api.eu-west-1.amazonaws.com/dev';

export const BASE_URL_UAT =
  'https://hd8f04xrbc.execute-api.eu-west-1.amazonaws.com/uat';

export const BASE_URL_PROD =
  'https://7by47hhrnk.execute-api.eu-west-1.amazonaws.com/production';

/* Marketting */
export const CANNED_PARAMETERES =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS ||
  '/api/marketing/content/v1/canned-params';

export const CANNED_PARAMETERES_BRANDS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_BRANDS ||
  '/api/marketing/content/v1/brands';

export const CANNED_PARAMETERES_COUNTRIES =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_COUNTRIES ||
  '/api/marketing/content/v1/countries';

export const CANNED_PARAMETERES_INDICATIONS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_INDICATIONS ||
  '/api/marketing/content/v1/indications';

export const CANNED_PARAMETERES_AUDIENCES =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_AUDIENCES ||
  '/api/marketing/content/v1/audiences';

export const CANNED_PARAMETERES_TONES =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_TONES ||
  '/api/marketing/content/v1/tones';

export const CANNED_PARAMETERES_PRIMARY_MESSAGES =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_PRIMARY_MESSAGES ||
  '/api/marketing/content/v1/topics';

export const CANNED_PARAMETERES_CHANNELS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_CHANNELS ||
  '/api/marketing/content/v1/channels';

export const CANNED_PARAMETERES_SECTIONS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_SECTIONS ||
  '/api/marketing/content/v1/sections';

export const CANNED_PARAMETERES_SEGMENTS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_SEGMENTS ||
  '/api/marketing/content/v1/segments';

export const CANNED_PARAMETERES_PLATFORMS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_PLATFORMS ||
  '/api/marketing/content/v1/platforms';

export const CANNED_PARAMETERES_ACTIONS =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_PLATFORMS ||
  '/api/marketing/content/v1/actions';

export const PREGENERATE =
  process.env.REACT_APP_API_URL_PREGENERATE ||
  '/api/marketing/content/v1/pregenerate';

export const CANNED_PARAMETERES_MODULES =
  process.env.REACT_APP_API_URL_CANNED_PARAMETERS_MODULES ||
  '/api/marketing/content/v1/email-modules';

export const GENERATE =
  process.env.REACT_APP_API_URL_GENERATE ||
  '/api/marketing/content/v1/generate';

export const GENERATE_STATUS =
  process.env.REACT_APP_API_URL_GENERATE_STATUS ||
  '/api/marketing/content/v1/generated-content/status';

export const REGENERATE =
  process.env.REACT_APP_API_URL_REGENERATE ||
  '/api/marketing/content/v1/regenerate';

export const FEEDBACK =
  process.env.REACT_APP_API_URL_FEEDBACK ||
  '/api/marketing/content/v1/feedback';

export const TRACK_CONTENT_COPY =
  process.env.REACT_APP_API_URL_TRACK_CONTENT_COPY ||
  '/api/marketing/content/v1/tracker/content-copy';

export const BRAND_DETAILS =
  process.env.REACT_APP_API_URL_BRAND_DETAILS ||
  '/api/marketing/content/v1/brand-details';

export const PRIMARY_MESSAGES =
  process.env.REACT_APP_API_URL_PRIMARY_MESSAGES ||
  '/api/marketing/content/v1/primary-messages';

const safeEncode = (value: string | number) =>
  value ? window.encodeURIComponent(value) : '';

export const CLAIMS_REFERENCES = (
  contentId: string,
  sessionId: string,
  action: string
): string => {
  let url = process.env.REACT_APP_API_URL_CLAIMS_REFERENCES;

  if (!url) url = '/api/marketing/content/v1/references';
  url += `/?contentId=${window.encodeURIComponent(
    contentId
  )}&sessionId=${sessionId}&action=${action}`;
  return url;
};

/* Medical */
export const MEDICAL_GENERATE_SUMMARY =
  process.env.REACT_APP_API_URL_MEDICAL_GENERATE_SUMMARY ||
  '/api/medical/content/v1/generate-summary';

export const MEDICAL_SUMMARY = (jobId: string): string => {
  let url = process.env.REACT_APP_API_URL_MEDICAL_SUMMARY;

  if (!url) url = '/api/medical/content/v1/summary/';

  url += window.encodeURIComponent(jobId);
  return url;
};

export const MEDICAL_FEEDBACK =
  process.env.REACT_APP_API_URL_MEDICAL_FEEDBACK ||
  '/api/medical/content/v1/feedback';

export const MEDICAL_FILE_UPLOAD =
  process.env.REACT_APP_API_URL_MEDICAL_FILE_UPLOAD ||
  '/api/medical/content/v1/upload-file';

export const MEDICAL_FILE_DELETE = (fileUid: string): string => {
  let url = process.env.REACT_APP_API_URL_MEDICAL_FILE_DELETE;

  if (!url) url = '/api/medical/content/v1/delete-file/';

  url += window.encodeURIComponent(fileUid);
  return url;
};

export const MEDICAL_GENERATE_FILE =
  process.env.REACT_APP_API_URL_MEDICAL_GENERATE_FILE ||
  '/api/medical/content/v1/generate-file';

export const MEDICAL_DOWNLOAD_FILE = (fileUid: string): string => {
  let url = process.env.REACT_APP_API_URL_MEDICAL_DOWNLOAD_FILE;

  if (!url) url = '/api/medical/content/v1/download-file';

  url += `?fileUid=${window.encodeURIComponent(fileUid)}`;
  return url;
};

/* translation */
export const LOCALIZATION_LANGUAGES =
  process.env.REACT_APP_API_URL_LOCALIZATION_LANGUAGES ||
  '/api/marketing/content/v1/languages';

export const LOCALIZATION_TRANSLATE =
  process.env.REACT_APP_API_URL_LOCALIZATION_TRANSLATE ||
  '/api/marketing/content/v1/translate';

/* partial regeneration */
export const PARTIAL_REGENERATE =
  process.env.REACT_APP_API_URL_PARTIAL_REGENERATE ||
  '/api/marketing/content/v1/partial-regenerate';

export const RECOMMEND_IMAGES =
  process.env.REACT_APP_API_URL_RECOMMEND_IMAGES ||
  '/api/marketing/content/v1/images/recommend';

export const GENERATE_CAPTION =
  process.env.REACT_APP_API_URL_GENERATE_CAPTION ||
  '/api/marketing/content/v1/asset/generate-caption';

export const CONTENT_ASSET = (fileUid: string, action: string = ''): string => {
  let url = process.env.REACT_APP_API_URL_CONTENT_ASSET;

  if (!url) url = '/api/marketing/content/v1/asset';

  url += `?imageName=${window.encodeURIComponent(fileUid)}`;
  if (action) {
    url += `&action=${action}`;
  }
  return url;
};

/**
 * API routes for users management
 */
export const GET_USER_INFO =
  process.env.REACT_APP_API_URL_GET_USER_INFO || '/api/auth/v1/user/info';

export const GET_USERS =
  process.env.REACT_APP_API_URL_GET_USERS || '/api/auth/v1/users';

export const UPDATE_USER_ROLE = (userId: string): string => {
  let url = process.env.REACT_APP_API_URL_UPDATE_USER_ROLE;

  if (!url) url = '/api/auth/v1/users/';

  url += window.encodeURIComponent(userId);
  return url;
};

export const CONTENT_CONFIG = (
  brandName: string,
  audienceName: string,
  channelName: string
) => {
  let url = process.env.REACT_APP_API_URL_CONTENT_CONFIG;

  if (!url) url = '/api/marketing/content/v1/content-config';

  url += `?brandName=${brandName}&audienceName=${audienceName}&channelName=${channelName}`;
  return url;
};

export const GET_GENERATED_CONTENT = (
  sessionId: string,
  action: string,
  contentId: string,
  contentGroupId: string,
  channel?: string
) => {
  let url = process.env.REACT_APP_API_URL_GET_GENERATED_CONTENT;
  if (!url) url = '/api/marketing/content/v1/generated-content';

  url += `?contentId=${window.encodeURIComponent(
    contentId
  )}&sessionId=${window.encodeURIComponent(
    sessionId
  )}&action=${window.encodeURIComponent(
    action
  )}&contentGroupId=${window.encodeURIComponent(contentGroupId)}`;
  if (channel) {
    url += `&channel=${window.encodeURIComponent(channel)}`;
  }

  return url;
};

export const GET_GENERATED_CONTENT_STATUS = (
  sessionId: string,
  action: string,
  contentId: string
) => {
  let url = GENERATE_STATUS;
  url += `?contentId=${window.encodeURIComponent(
    contentId
  )}&sessionId=${window.encodeURIComponent(
    sessionId
  )}&action=${window.encodeURIComponent(action)}`;

  return url;
};

export const GET_BRAND_DETAILS = (
  countryName: string,
  brandName: string,
  indicationName?: string,
  audiences?: (string | undefined)[],
  includes?: (string | undefined)[],
  claimSource?: string
) => {
  let url = process.env.REACT_APP_API_URL_BRAND_DETAILS;
  if (!url) {
    url = '/api/marketing/content/v1/brand-details';
  }

  const audiencesEncoded = Array.isArray(audiences)
    ? safeEncode(audiences.join(','))
    : '';

  const includesEncoded = Array.isArray(includes)
    ? safeEncode(includes.join(','))
    : '';

  const params = [];

  params.push(`countryName=${safeEncode(countryName)}`);
  params.push(`brandName=${safeEncode(brandName)}`);

  if (indicationName && indicationName != null)
    params.push(`indicationName=${safeEncode(indicationName)}`);
  if (audiencesEncoded && audiencesEncoded != null)
    params.push(`audiences=${audiencesEncoded}`);
  if (includes && includes != null) params.push(`includes=${includesEncoded}`);
  if (claimSource && claimSource != null)
    params.push(`claimSource=${safeEncode(claimSource)}`);

  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return url;
};

export const GET_PRIMARY_MESSAGES = (
  countryName: string,
  brandName: string,
  indicationName?: string,
  audiences?: (string | undefined)[]
) => {
  let url = process.env.REACT_APP_API_URL_PRIMARY_MESSAGES;
  if (!url) {
    url = '/api/marketing/content/v1/primary-messages';
  }

  const audiencesEncoded = Array.isArray(audiences)
    ? safeEncode(audiences.join(','))
    : '';

  const params = [];

  params.push(`countryName=${safeEncode(countryName)}`);
  params.push(`brandName=${safeEncode(brandName)}`);

  if (indicationName && indicationName != null)
    params.push(`indicationName=${safeEncode(indicationName)}`);
  if (audiencesEncoded && audiencesEncoded != null)
    params.push(`audiences=${audiencesEncoded}`);

  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return url;
};

/**
 * WF Integration
 */
export const WF_TASK_DETAILS = (taskId: string, projectId: string) => {
  let url = process.env.REACT_APP_API_URL_WF_TASK_DETAILS;
  if (!url) url = '/api/marketing/content/v1/task-details';

  url += `?taskId=${window.encodeURIComponent(
    taskId
  )}&projectId=${window.encodeURIComponent(projectId)}`;

  return url;
};

export const GET_EXTRACTION_DETAILS = (sessionId: string) => {
  let url = process.env.REACT_APP_API_URL_GET_EXTRACTION_DETAILS;
  if (!url) url = '/api/marketing/content/v1/file/extraction-details';

  url += `?sessionId=${sessionId}`;

  return url;
};

/**
 * file uploads and file processings
 */

export const FILE_UPLOAD =
  process.env.REACT_APP_API_URL_FILE_UPLOAD ||
  '/api/marketing/content/v1/file/upload';

export const FILE_PROCESS =
  process.env.REACT_APP_API_URL_FILE_PROCESS ||
  '/api/marketing/content/v1/file/process';

export const FILE_PROCESS_STATUS = (sessionId: string) => {
  let url = process.env.REACT_APP_API_URL_FILE_PROCESS_STATUS;
  if (!url) url = '/api/marketing/content/v1/file/process/status';

  url += `?sessionId=${sessionId}`;

  return url;
};

export const FILE_DELETE = (sessionId: string, fileName: string) => {
  let url = process.env.REACT_APP_API_URL_FILE_DELETE;
  if (!url) url = '/api/marketing/content/v1/file/delete';

  url += `?sessionId=${sessionId}&fileName=${window.encodeURIComponent(
    fileName
  )}`;

  return url;
};

export const FILE_EXCTRACTION_DETAILS =
  process.env.REACT_APP_API_URL_FILE_EXCTRACTION_DETAILS ||
  '/api/marketing/content/v1/file/extraction-details';

/**
 *
 * user
 */

export const USER_SESSION =
  process.env.REACT_APP_API_URL_USER_SESSION ||
  '/api/marketing/content/v1/user/session';
export const GET_USER_SESSION_URL = (action: string): string => {
  const url = USER_SESSION;
  return `${url}?action=${action}`;
};

export const GET_FILE_UPLOAD_PRESIGNED = () => {
  const url = process.env.REACT_APP_API_URL_PRESIGNED;
  if (!url) return 'https://presigned/file_upload_test_2.pdf';
  return url;
};

export const SUPPORTING_INFORMATION =
  process.env.REACT_APP_API_URL_SUPPORTING_INFORMATION ||
  '/api/marketing/content/v1/supporting-info';

export const GENERATED_CONTENT_VALIDATION =
  process.env.REACT_APP_API_URL_GENERATED_CONTENT_VALIDATION ||
  '/api/marketing/content/v1/generated-content/validation';

export const GET_SEGMENT_DETAILS = (
  brandName: string,
  audiences?: (string | undefined)[]
) => {
  const audiencesEncoded = Array.isArray(audiences)
    ? safeEncode(audiences.join(','))
    : '';

  let url = process.env.REACT_APP_API_SEGMENT_DETAILS;
  if (!url) url = '/api/marketing/content/v1/segment-details';

  url += `?brandName=${safeEncode(brandName)}`;
  if (audiencesEncoded) {
    url += `&audiences=${audiencesEncoded}`;
  }

  return url;
};

export const GET_CLAIM_DETAILS = ({
  countryName,
  brandName,
  indicationName,
  audiences,
  primaryMessages,
  language,
  pageSize = 20,
  page = 1,
}: {
  countryName: string;
  brandName: string;
  indicationName?: string;
  audiences?: (string | undefined)[];
  primaryMessages?: (string | undefined)[];
  language?: string;
  pageSize?: number;
  page?: number;
}) => {
  let url = process.env.REACT_APP_API_SEGMENT_DETAILS;
  if (!url) url = '/api/marketing/content/v1/claim-details';

  const audiencesEncoded = Array.isArray(audiences)
    ? safeEncode(audiences.join(','))
    : '';

  const primaryMessagesEncoded = Array.isArray(primaryMessages)
    ? safeEncode(primaryMessages.join(','))
    : '';

  // Start building the query string
  const params = [];

  if (countryName) params.push(`countryName=${safeEncode(countryName)}`);
  if (brandName) params.push(`brandName=${safeEncode(brandName)}`);
  if (indicationName)
    params.push(`indicationName=${safeEncode(indicationName)}`);
  if (audiencesEncoded) params.push(`audiences=${audiencesEncoded}`);
  if (primaryMessagesEncoded)
    params.push(`primaryMessages=${primaryMessagesEncoded}`);
  if (language) params.push(`language=${safeEncode(language)}`);
  if (page) params.push(`page=${safeEncode(page)}`);
  if (pageSize) params.push(`pageSize=${safeEncode(pageSize)}`);

  // Append query string if there are valid parameters
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return url;
};

export const GET_IMAGES = ({
  countryName,
  brandName,
  indicationName = 'ANY',
  nextPageToken = '',
}: {
  countryName: string;
  brandName: string;
  indicationName?: string;
  nextPageToken?: string | null;
}) => {
  let url = process.env.REACT_APP_API_IMAGES;
  if (!url) url = '/api/marketing/content/v1/images';

  // Start building the query string
  const params = [];

  if (countryName) params.push(`countryName=${safeEncode(countryName)}`);
  if (brandName) params.push(`brandName=${safeEncode(brandName)}`);
  if (indicationName)
    params.push(`indicationName=${safeEncode(indicationName)}`);
  if (nextPageToken) params.push(`nextPageToken=${safeEncode(nextPageToken)}`);

  // Append query string if there are valid parameters
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return url;
};

export const DOWNLOAD_IMAGES = ({
  brandName,
  countryName,
  indicationName = 'ANY',
}: {
  countryName: string;
  brandName: string;
  indicationName?: string;
}) => {
  let url = process.env.REACT_APP_API_IMAGES_DOWNLOAD;
  if (!url) url = '/api/marketing/content/v1/images/download';
  const params = [];
  if (countryName) params.push(`countryName=${safeEncode(countryName)}`);
  if (brandName) params.push(`brandName=${safeEncode(brandName)}`);
  if (indicationName)
    params.push(`indicationName=${safeEncode(indicationName)}`);
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return url;
};
