import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type ApiPayloadUsed = {
  brand: string;
  country: string;
  indication?: string;
  targetAudience?: (string | undefined)[];
  language?: string;
  topics?: (string | undefined)[];
  claimsPage?: number;
  claimsPageSize?: number;
  nextPageToken?: string;
  includes?: (string | undefined)[];
};

export type Segment = {
  id: string;
  name: string;
  label: string;
  description: string;
};

export type AdditionalBrandDetail = {
  indicationName: string;
  indicationBrandDescription: string;
};

export type PrescribingInfo = {
  id: string;
  label: string;
  link: string;
};

export type SafetyInfo = {
  id: string;
  text: string;
};

export type SupportingInformationType = {
  prescribingInfo: PrescribingInfo[];
  safetyInfo: SafetyInfo;
};

export type SupportingInfo = {
  audienceName: string;
  supportingInfo: SupportingInformationType;
};

export type Indication = {
  id: string;
  name: string;
  label: string;
  shortName: string;
  longName: string;
  inclusions: string[];
  exclusions: string[];
};

export type BrandDetailsData = {
  id: string;
  name: string;
  description: string;
  additionalBrandDetails: AdditionalBrandDetail[];
  supportingInfo: SupportingInfo[];
  indications: Indication[];
};

export type BrandDetails = {
  data: BrandDetailsData;
  isLoading: boolean;
  hasNoData: boolean;
  errorMessage: string;
};

export type SegmentData = {
  audience: string;
  segments: Segment[];
};

export type SegmentDetail = {
  data: {
    segments: SegmentData[];
  };
  isLoading: boolean;
  hasNoData: boolean;
  errorMessage: string;
};

export type ClaimDetails = {
  data: {
    claims: Claim[];
    claimsPagination: Pagination;
  };
  isLoading: boolean;
  hasNoData: boolean;
  errorMessage: string;
};

export type ImageDetails = {
  data: {
    images: Image[];
    imagesPagination: Pagination;
  };
  isLoading: boolean;
  hasNoData: boolean;
  errorMessage: string;
};

export type Image = {
  name: string;
  url: string;
};

export type Claim = {
  id: string;
  code: string;
  text: string;
  revisedText: string;
  primaryMessage: string;
  secondaryTopics: string[];
  referenceLink: string;
  references: string[];
  rank: number;
};

export type Pagination = {
  total: number;
  page: number;
  totalPages: number;
  nextPage: number | null;
  previousPage: number | null;
  nextPageToken?: string | null;
};

type BrandInformation = {
  apiPayloadUsed: ApiPayloadUsed;
  brandDetails: BrandDetails;
  segmentDetails: SegmentDetail;
  claimDetails: ClaimDetails;
  images: ImageDetails;
  isLoading: boolean;
  errorMessage: string;
  errorFetchSegment: boolean;
};

export const brandInformationStateSegment =
  stateSegmentWithReset<BrandInformation>({
    apiPayloadUsed: {
      brand: '',
      country: '',
      indication: '',
      targetAudience: [],
      language: '',
      topics: [],
    },
    brandDetails: {
      data: {
        id: '',
        name: '',
        description: '',
        additionalBrandDetails: [],
        supportingInfo: [],
        indications: [],
      },
      isLoading: false,
      hasNoData: false,
      errorMessage: '',
    },
    segmentDetails: {
      data: {
        segments: [],
      },
      isLoading: false,
      hasNoData: false,
      errorMessage: '',
    },
    claimDetails: {
      data: {
        claimsPagination: {
          total: 0,
          page: 0,
          totalPages: 0,
          nextPage: null,
          previousPage: null,
        },
        claims: [],
      },
      isLoading: false,
      hasNoData: false,
      errorMessage: '',
    },
    images: {
      data: {
        imagesPagination: {
          total: 0,
          page: 0,
          totalPages: 0,
          nextPage: null,
          previousPage: null,
          nextPageToken: '',
        },
        images: [],
      },
      isLoading: false,
      hasNoData: false,
      errorMessage: '',
    },
    isLoading: false,
    errorMessage: '',
    errorFetchSegment: false,
  });

brandInformationStateSegment.debugLabel = 'brandInformationStateSegment';
