import store from '../..';
import { GET_SEGMENT_DETAILS } from '../../../../constants/apiRoutes';
import { ApiError } from '../../../../types/Content';
import { httpClient } from '../../../../utils/httpClient';
import Logger from '../../../../utils/logger';
import {
  ApiPayloadUsed,
  brandInformationStateSegment,
  SegmentData,
} from '../../stateSegments/brandsInformationStateSegment';

export interface SegmentResponse {
  success: boolean;
  data?: {
    segmentDetails: SegmentData[] | [];
  };
}

export const getSegmentDetails = async (
  apiPayload: ApiPayloadUsed
): Promise<SegmentResponse> => {
  const { brand, targetAudience } = apiPayload;
  try {
    const response = await httpClient.get<SegmentResponse>(
      GET_SEGMENT_DETAILS(brand, targetAudience)
    );
    const { data, success } = response as SegmentResponse;

    if (success && data) {
      return {
        success: true,
        data,
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    const postError = error as ApiError;
    Logger.error(new Error(postError.message));
    return {
      success: false,
    };
  }
};

export const fetchSegmentDetails = async (payload: ApiPayloadUsed) => {
  store.set(brandInformationStateSegment, (prev) => {
    return {
      ...prev,
      segmentDetails: {
        ...prev.segmentDetails,
        isLoading: true,
        errorMessage: '',
      },
    };
  });

  const { success, data } = await getSegmentDetails({
    ...payload,
  });

  if (success && data) {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        segmentDetails: {
          ...prev.segmentDetails,
          data: {
            segments: data.segmentDetails ?? [],
          },
          isLoading: false,
        },
      };
    });
  } else {
    store.set(brandInformationStateSegment, (prev) => {
      return {
        ...prev,
        segmentDetails: {
          ...prev.segmentDetails,
          isLoading: false,
          errorMessage: 'There has been an error in fetching segment details',
        },
      };
    });
  }

  return success;
};
