export enum FormFields {
  BRAND = 'BRAND',
  INDICATION = 'INDICATION',
  COUNTRY = 'COUNTRY',
  AUDIENCE = 'AUDIENCE',
  CHANNEL = 'CHANNEL',
  SEGMENT = 'SEGMENT',
  TONE = 'TONE',
  SECTION = 'SECTION',
  PRIMARYMESSAGE = 'PRIMARYMESSAGE',
  PLATFORM = 'PLATFORM',
  MODULE = 'MODULE',
  ACTION = 'ACTION',
  DESTINATION_FORMAT = 'DESTINATION_FORMAT',
  LANGUAGE = 'LANGUAGE',
  PRIMARY_MESSAGES = 'PRIMARY_MESSAGES',
}

export type FormFieldsPayload = {
  [key: string]: string | string[] | undefined;
  brandName?: string;
  audienceName?: string;
  channelName?: string;
  countryName?: string;
};
