export const EmailIntroIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 28C0.447715 28 0 28.4477 0 29C0 29.5523 0.447715 30 1 30H63C63.5523 30 64 29.5523 64 29C64 28.4477 63.5523 28 63 28H1ZM1 34C0.447715 34 0 34.4477 0 35C0 35.5523 0.447715 36 0.999999 36H53C53.5523 36 54 35.5523 54 35C54 34.4477 53.5523 34 53 34H1Z"
      fill="#18181B"
    />
  </svg>
);
