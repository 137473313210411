import { atom as stateSegment } from 'jotai';
import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

import {
  EmailScreenName,
  ModuleContent,
  SelectedModule,
} from '../../../types/store/markettingContentGeneration/emailModules';

/**
 * When the email modules are selected/their count is updated, this state will be synced
 *
 * Sample Data:
 *
 * new Map([
 *  [
 *    "gl_header_c1",
 *    {
 *      name: "GL_Header_C1",
 *      moduleKey: "gl_header_c1",
 *      label: "GL Header C1",
 *      count: 2
 *    }
 *  ],
 * ])
 *
 */
export const selectedModulesStateSegment = stateSegmentWithReset<
  Map<string, SelectedModule>
>(new Map());

selectedModulesStateSegment.debugLabel = 'selectedModulesStateSegment';

/**
 * On “Generate” / “Add modules”, this state will be fed with the selected module data after the prefetch call.
 * Data will be generated from the @constant selectedModulesStateSegment using @method createModules from @module useEmailModules (a custom hook)
 *
 * Sample Data: Default values for the modules when created
 *
 * new Map([
 *   [
 *    "gl_body_c1_1",
 *    {
 *       moduleKey: "gl_body_c1",
 *       mapKey: "gl_body_c1_1",
 *       parentContentId: "",
 *       name: "GL_Body_C1",
 *       label: "GL Body C1_1",
 *       translationConfig: {},
 *       refineConfig: {},
 *       status: "Not Started",
 *       hasErrorGeneratingContent: false,
 *       hasContentGenerated: false,
 *       hasImageSelected: false,
 *       hasOptionSelected: false,
 *       refinedOptions: [],
 *       selectedContentVersion: null,
 *       selectedOptionLanguage: "",
 *       selectedOption: null,
 *       selectedOptionId: "",
 *       selectedImage: null,
 *       selectedImageName: "",
 *       selectedImageCaption: "",
 *       sequence: 1,
 *       generatePayload: null,
 *       isDisabled: false,
 *       contentId: "",
 *       primaryMessage: ""
 *     }
 *   ],
 * ])
 *
 */
export const moduleContentsStateSegment = stateSegmentWithReset<
  Map<string, ModuleContent>
>(new Map());

moduleContentsStateSegment.debugLabel = 'moduleContentsStateSegment';

/**
 * Content Loader will render the following components based on the below values from this state.
 *	 modules-dashboard - ModulesSelectionDashboard - @module ModulesSelectionDashboard
 *	 modules-preview - EmailModulePreview - @module EmailModulePreview
 *	 modules-content - ContentPresenter - @module ContentPresenter
 */
export const activeEmailScreenStateSegment =
  stateSegmentWithReset<EmailScreenName>(null);

activeEmailScreenStateSegment.debugLabel = 'activeEmailScreenStateSegment';

/**
 * Stores the current module's parent content ID
 * Will be used in claims API request
 */
export const emailContentParentId = stateSegmentWithReset('');

emailContentParentId.debugLabel = 'emailContentParentId';

/**
 * This state flag tracks whether the content is generated atleast for any one of the selected modules
 * Based on this, the "Generate" CTA in the control panel turns into "Reset All Modules" CTA
 */
export const hasStartedGeneratingContent = stateSegment((get) => {
  const moduleContents = get(moduleContentsStateSegment);
  return Array.from(moduleContents.values()).some(
    (value) => value.status !== 'Not Started'
  );
});

hasStartedGeneratingContent.debugLabel = 'hasStartedGeneratingContent';

/**
 * This state holds the copy of @constant selectedModulesStateSegment
 * This is to track whether
 *    the user tries to select any additional module or
 *    increases/decreases the count of already selected modules
 *
 * If anyone of the above scenarios are met,
 * the "Reset All Modules" CTA becomes "Add Modules" CTA
 */
export const previouslySelectedModules = stateSegmentWithReset<
  Map<string, SelectedModule>
>(new Map());

previouslySelectedModules.debugLabel = 'previouslySelectedModules';
