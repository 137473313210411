import { atomWithReset as stateSegmentWithReset } from 'jotai/utils';

export type ContentConfig = {
  recommendImages?: boolean;
  enableTranslation?: boolean;
};

export const contentConfigStateSegment = stateSegmentWithReset<ContentConfig>({
  recommendImages: true,
  enableTranslation: false,
});
contentConfigStateSegment.debugLabel = 'contentConfigStateSegment';
