import React, { useState } from 'react';
import styles from './NavMenuItem.module.css';
import Tag, { TagVariant } from '../Tag';
import { translate } from '../../../localization';
import Tooltip, { ToolTipSize, TooltipVariant } from '../Tooltip';
import PointingTooltip, { TooltipPosition } from '../PointingTooltip';

// Props type for NavMenuItem component
interface NavMenuItemProps {
  icon: React.ReactNode;
  text: string;
  tooltip: string;
  isExpanded: boolean;
  handleMenuItemClick: () => void;
  className?: string;
  isActive: boolean;
  isBetaFeature?: boolean;
}

// NavMenuItem component
const NavMenuItem: React.FC<NavMenuItemProps> = ({
  icon,
  text,
  tooltip,
  isExpanded,
  handleMenuItemClick,
  className,
  isActive = false,
  isBetaFeature = false,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const onKeyUpHandler = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter') {
      handleMenuItemClick();
    }
  };

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isExpanded ? (
        <li
          className={`${styles['nav-menu-item']} ${
            styles.expanded
          } ${className} ${isActive ? styles.active : ''}`}
          role="menuitem"
          tabIndex={0}
          onClick={handleMenuItemClick}
          aria-expanded={isExpanded}
          onKeyUp={onKeyUpHandler}
        >
          {icon}
          <span className={styles.text}>{text}</span>
          {isBetaFeature && (
            <PointingTooltip
              text={translate('beta_tooltip')}
              showTooltip={isTooltipVisible && isBetaFeature}
              onMouseEnter={showTooltip}
              toolTipPosition={TooltipPosition.RIGHT}
              onMouseLeave={hideTooltip}
              id="beta-tag-tooltip"
              classes={styles.betaTooltipLarge}
            >
              <Tag
                text={translate('beta')}
                variant={TagVariant.NEUTRAL}
                icon={null}
                classes={styles.betaTagText}
              />
            </PointingTooltip>
          )}
        </li>
      ) : (
        <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
          {/* BASIC SYSTEM TOOLTIP EXAMPLE */}
          {/* TODO: Create a new Tooltip variant using the tooltip implementation used below,
          so that it can be used in multiple places */}
          <Tooltip
            text={tooltip}
            showTooltip={isTooltipVisible}
            onClickedOutside={() => setTooltipVisible(false)}
            variant={TooltipVariant.TOP_RIGHT}
            size={ToolTipSize.REGULAR}
            theme="default"
            id="left-nav-tooltip"
            classes={styles.leftNavTooltip}
          >
            <li
              className={`${styles['nav-menu-item']} ${className} ${
                isActive ? styles.active : ''
              }`}
              role="menuitem"
              tabIndex={0}
              onClick={handleMenuItemClick}
              aria-expanded={isExpanded}
              onKeyUp={onKeyUpHandler}
            >
              {icon}
            </li>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default NavMenuItem;
