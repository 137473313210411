import get from 'lodash/get';
import { RESET } from 'jotai/utils';
import {
  ContentConfig,
  fetchContentConfig,
} from '../../../../services/Content';
import { MarketingFormField } from '../../../../types/MarketingBox';
import { contentConfigStateSegment } from '../../stateSegments/contentConfigStateSegment';
import store from '../..';

export const loadContentConfig = async (
  brand: string,
  targetAudience = '',
  contentType = ''
) => {
  const { data, success } = await fetchContentConfig({
    brand,
    targetAudience,
    contentType,
  } as MarketingFormField);

  if (data && success) {
    const recommendImages = get(
      (data as ContentConfig['data'])?.contentConfig,
      'recommendImages',
      true
    );
    const enableTranslation = get(
      (data as ContentConfig['data'])?.contentConfig,
      'enableTranslation',
      false
    );

    store.set(contentConfigStateSegment, (prev) => {
      return {
        ...prev,
        recommendImages,
        enableTranslation,
      };
    });
  } else {
    store.set(contentConfigStateSegment, RESET);
  }
};
