import { Dispatch, SetStateAction } from 'react';
import get from 'lodash/get';
import { ActiveEmailModule } from '../../../../types/store/markettingContentGeneration/emailModules';
import {
  getCurrentSelectedLanguage,
  doesImageCaptionExists,
  doesImageRecommendExists,
  updateLanguageOptions,
  updateRecentTranslations,
} from '../../helpers/common';
import { clearTranslationErrors } from './clearTranslationErrors';
import store from '../../index';
import { contentListStateSegment } from '../../stateSegments/contentListSegment';
import { convertTextToLowerCase } from '../../../../utils/convertTextToLowerCase';
import { contentTranslationStateSegment } from '../../stateSegments/translationSegment';
import { fetchTranslatedContent } from '../../../../services/Content';
import { moduleContentsStateSegment } from '../../stateSegments/emailModulesStateSegment';
import { ContentType } from '../../../../constants/common';
import { updateTranslatedCaptionOnImageSegment } from '../microProcessActions';

export const generateTranslatedContent = async (
  currentViewVersion: number,
  langCode: string,
  isFetched: boolean,
  action: string,
  setActiveModuleDetails: Dispatch<SetStateAction<ActiveEmailModule>>,
  activeModuleDetails: ActiveEmailModule,
  destinationFormat?: string
) => {
  const currentData = store.get(contentListStateSegment).contentList[
    currentViewVersion
  ];
  const contentType = convertTextToLowerCase(
    get(currentData, 'paramsUsed.contentType.name', '')
  );

  const contentId = get(currentData, `${getCurrentSelectedLanguage()}.id`);

  updateLanguageOptions(langCode);
  clearTranslationErrors();

  if (isFetched) return;

  store.set(contentTranslationStateSegment, (prev) => {
    return {
      ...prev,
      isTranslatingContent: true,
    };
  });

  const { success, data } = await fetchTranslatedContent({
    contentId,
    languageId: langCode,
    contentType,
    action,
    destinationFormat,
  });

  if (success && data) {
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            langCode,
            data,
            isFetched: true,
          },
        },
      };
    });
    updateRecentTranslations(
      contentId,
      currentViewVersion?.toString() || '',
      langCode
    );
    const moduleContents = new Map(
      Array.from(store.get(moduleContentsStateSegment)).values()
    );
    const module = moduleContents.get(activeModuleDetails.mapKey);
    if (module) {
      if (activeModuleDetails.selectedContentVersion === currentViewVersion) {
        moduleContents.set(activeModuleDetails.mapKey, {
          ...module,
          hasImageSelected: false,
          hasOptionSelected: false,
          selectedContentVersion: null,
          selectedOptionLanguage: '',
          selectedOption: null,
          selectedOptionId: '',
          selectedImage: null,
          selectedImageName: '',
          selectedImageCaption: '',
          status: 'In Progress',
          translationConfig: {
            ...module.translationConfig,
            [currentViewVersion]: langCode,
          },
        });
        store.set(moduleContentsStateSegment, moduleContents);
        setActiveModuleDetails((prev) => ({
          ...prev,
          selectedContentVersion: null,
          status: 'In Progress',
        }));
      } else {
        moduleContents.set(activeModuleDetails.mapKey, {
          ...module,
          translationConfig: {
            ...module.translationConfig,
            [currentViewVersion]: langCode,
          },
        });
        store.set(moduleContentsStateSegment, moduleContents);
      }
    }

    if (
      contentType === ContentType.EMAIL &&
      doesImageCaptionExists(contentId) &&
      doesImageRecommendExists(data)
    ) {
      updateTranslatedCaptionOnImageSegment(contentId, data);
    }
  } else {
    // resetTranslationState();
    store.set(contentTranslationStateSegment, (prev) => {
      return {
        ...prev,
        errorTranslatingContent: true,
        isTranslatingContent: false,
        map: {
          ...prev.map,
          [contentId]: {
            langCode,
            data,
            isFetched: false,
            retryParams: { currentViewVersion, langCode },
          },
        },
      };
    });
  }
};
