export enum ImageRecommendationStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
  NO_IMAGES = 'no-images',
}

export type ContentImages = {
  imageBlob: Blob;
  imageName: string;
  imageType: string;
};
